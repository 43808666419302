.cast-selector {
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
}
.cast-selector__radio {
  justify-self: start;

  min-height: 2.5rem;
}

.selected-tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.selected-tags__send-to {
  font-weight: 700;
}
