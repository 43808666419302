.userDetailChannelChat{
    border: 1px solid #1E8C96;
    color: #1E8C96;
    background-color: #fafafa;
}
.userDetailChannelLine{
    background-color: #00B900;
}
.userDetailChannelFb{
    background-color: #4267B2;
}
.userDetailChannelEmail{
    background-color: #FF7B00;
}
.userDetailChannelDetailChat{
    border-color: #1E8C96;
    color: #1E8C96;
    background-color: #a1e1e7;
}
.userDetailChannelDetailChat span{
    color: #1E8C96;
}
.userDetailChannelDetailLine{
    border-color: #00B900;
    color: #00B900;
    background-color: #CCFFCC;
}
.userDetailChannelDetailLine span{
    color: #00B900;
}
.userDetailChannelDetailLineTitle{
    background-color: #00B900;
}
.userDetailChannelDetailLineTitle span{
    color: #FFF;
}
.userDetailChannelDetailFb{
    border-color: #4267B2;
    color: #4267B2;
    background-color: #d3dcf5;
}
.userDetailChannelDetailFb span{
    color: #4267B2;
}
.userDetailChannelDetailFbTitle{
    background-color: #4267B2;
}
.userDetailChannelDetailFbTitle span{
    color: #FFF;
}
.userDetailChannelDetailEmail{
    border-color: #FF7B00;
    color: #ff7b00;
    background-color: #ffebd4;
}
.userDetailChannelDetailEmail span{
    color: #FF7B00;
}
.userDetailChannelDetailEmailTitle{
    background-color: #FF7B00;
}
.userDetailChannelDetailEmailTitle span{
    color: #FFF;
}