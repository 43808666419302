.container {
  max-width: 80vw;
  min-width: 400px;
  width: 700px;
  margin: 0 auto;
  padding: 10px 20px;
}
.container--modal {
  max-height: 450px;
  overflow-y: auto;
}

@media screen and (width < 650px) {
  .container {
    max-width: 90vw;
  }
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  font-weight: 600;
  margin-bottom: 1.5rem;
}

.date-time-picker {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  min-height: 2.5rem;
  margin-bottom: 0.5rem;
}

.button {
  width: 100%;

  margin-top: 1rem;
}

.messages__title {
  display: block !important;

  width: 100%;
  margin-top: 1rem;

  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.label {
  display: block !important;

  width: 100%;

  font-size: 16px;
  font-weight: 500;
  text-align: start;
}
.loading-spinner__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
}
