.backdrop {
  z-index: 100;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100%;

  background-color: #ffffff55;
}
