.container {
  max-height: 450px;
  max-width: 650px;
  min-width: 550px;
  width: max-content;
  margin: 0 auto;
  padding: 10px 20px;
  padding-top: 0;

  overflow-y: auto;
  overflow-x: auto;
}

@media screen and (width < 650px) {
  .container {
    max-width: 95vw;
    min-width: 90vw;
    padding-top: 0;
  }
}

.mutation-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.mutation-form > p {
  margin: 0;
}
.mutation-form__buttons {
  display: flex;
  justify-content: end;
  gap: 0.5rem;
}

.message-state {
  text-align: center;
}
.message-state__comment {
  color: red;
}
.message-state--canceled {
  color: red;
}
.message-state--sended {
  color: #1677ff;
}

.loading-spinner__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
}

.loading-spinner__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
}
