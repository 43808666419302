.button {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 1.5rem;
  width: 1.5rem;
  border: none;
  border-radius: 9999px;
  overflow: hidden;

  border-color: #dc2626 !important;
  color: white !important;
  background-color: #dc2626 !important;
}

.button:hover {
  border-color: #ef4444 !important;
  background-color: #ef4444 !important;
}

.button svg {
  height: 1rem;
  width: 1rem;
}
