.line-message__calendar__container {
  padding: 1rem;
  margin: 0px auto;
  width: 800px;
}

/* Week text */
.line-message__calendar__container .fc-col-header-cell-cushion {
  color: black;
  text-decoration: none;

  cursor: default;
}

/* Date cell text */
.line-message__calendar__container .fc-daygrid-day-number {
  color: black;
  text-decoration: none;

  cursor: default;
}

/* Month view event block */
.line-message__calendar__container .fc-daygrid-event-dot {
  display: none;
}
.line-message__calendar__container .fc-daygrid-event {
  display: grid;
  grid-template-columns: min-content 1fr;
}
.line-message__calendar__container .fc-daygrid-event .fc-event-title {
  grid-column: 1 / -1;
}
.line-message__calendar__container .event__blue {
  border-color: #1677ff;
  background-color: #1677ff;
}

/* Event time */
.line-message__calendar__container .fc-daygrid-day-frame .fc-event-time {
  color: #fff;
}

/* Event title */
.line-message__calendar__container .fc-daygrid-day-frame .fc-event-title {
  font-weight: 400;
  color: #fff;
}
