/* Form 下方的DIV */
.ant-form-item {
    margin: 0;
}
/* 上傳功能區塊 */
.ant-upload-wrapper{
    display: flex;
    flex-wrap: wrap;
    /* margin-bottom: 10px; */
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    /* margin-right: 87px; */
    margin-right: 111px;
    position: relative;
    /* top: 85px;
    right: -6px; */
    top: 89px;
    right: -5px;
}
/* Upload按鈕 */
.ant-upload-wrapper .ant-upload-select {
    display: flex;
    /* flex: 1; */
    justify-content: flex-end;

    z-index: 2;
    position: relative;
    top: -32px;
    /* top: -14px; */
}
/* 預覽圖片列表水平並列 */
.ant-upload-list{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    z-index: 10;
}
/* 預覽圖片寬度 */
.ant-upload-list-picture {
    flex: 8;
    margin-right: 7px;
    margin-left: -2px;
    position: relative;

    top: -10px;
    background-color: #FFF;
}
/* 上傳檔案預覽置中 */
.ant-upload-list-item-thumbnail{
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-upload-list-item-container{
    width: 100%;
    padding-left: 4px;
    max-width: 393px;
}/* END 預覽圖片寬度 */
.ant-upload-list-item-name{
    color: #FFF
}
.ant-upload-list-item-container div:nth-child(1){
    margin-top: 0px!important;
}
/* 文件上傳中的預覽文字顏色 */
.ant-upload-list-item-name{
    color: #666;
}
/* UserDetail中刪除icon */
/* span.anticon.anticon-delete {
    margin-left: 2px;
    display: flex;
    align-items: center;
} */
/* 可選擇標籤 */
/* .ant-tag-checkable-checked */
.ant-tag.ant-tag-checkable{
    padding: 2px 5px;
    font-size: 14px;
    color: #1d39c4;
    background: #f0f5ff;
    border-color: #adc6ff;
}
.ant-tag.ant-tag-checkable.ant-tag-checkable-checked{
    /* padding: 2px 5px;
    font-size: 14px;
    color: #fff;
    background: #576ac9;
    border-color: #adc6ff; */
    display: none;
}
.ant-tag.ant-tag-checkable:hover{
    color: #1d39c4;
    background: #dce8ff;
    border-color: #adc6ff;
}
/* .ant-tag.ant-tag-checkable.ant-tag-checkable-checked:hover{
    color: #fff;
    background: #7d8fe7;
    border-color: #adc6ff;
} */
/* filter版面 */
span.ant-dropdown-menu-title-content{
    display: flex;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-title-content{
    height: 18px;
}
/* filter搜尋圖示 */
.ant-input-search >.ant-input-group >.ant-input-group-addon:last-child .ant-input-search-button {
    display: flex;
    align-items: center;
    justify-content: center;
}
/* filter icon置中 */
.ant-input-affix-wrapper .ant-input-clear-icon{
    line-height: 12px;
}
/* emoji modal寬度 */
.ant-modal-content{
    padding: 0!important;
    width: fit-content!important;
}
@media screen and (max-width: 1275px) {
    .ant-upload-list-item-container{
        max-width: 260px;
    }
}
@media screen and (max-width: 1013px) {
    .ant-upload-wrapper .ant-upload-select {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .ant-upload-list-picture {
        width: 100%!important;
        display: flex;
        justify-content: flex-end;
        margin-right: initial;
    }
    .ant-upload-list-item-container div:nth-child(1){
        margin-top: 8px!important;
    }
}
@media screen and (max-width: 767px) {
    .ant-upload-list-item-container{
        max-width: 230px;
    }/* END 預覽圖片寬度 */
}