h6{
    font-weight: 600;
    margin-bottom: 5px;
}
.userDetailSec{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* margin: 18px 0; */
}
.userDetailSec2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 18px 12px;
}
.userDetailTitleSec{
    margin: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 4;
    width: 100%;
    /* min-width: 260px; */
    padding: 18px;
    background-color: #001529;
    border-left: 1px solid white;
    box-shadow: 2px 5px 10px -5px #33333366;
    /* height: 61px; */
}
.userDetailH6{
    margin: 0;
    width: 100%;
    color: #FFF;
    font-size: 1rem;
}
.userDetailCustomerDataSec{
    box-shadow: 1px 1px 5px -1px #888;
    border-radius: 5px;
    /* min-width: 280px;
    max-width: 290px; */
    width: 100%;
    margin: 0 0 12px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.userDetailCustomerDataSec2{
    /* border: 1px solid #001529;
    border-width: 1px; */
    box-shadow: 1px 1px 5px -1px #888;
    border-radius: 5px;
    /* min-width: 280px;
    max-width: 290px; */
    width: 100%;
    margin: 0 0 12px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.userDetailCustomerDataSec3{
    /* min-width: 250px;
    max-width: 260px; */
    border-radius: 5px;
    width: 100%;
    margin: 0 0 12px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.userDetailDiv{
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 8px 0 0;
}
/* .userDetailUserNumberDiv{
    background-color: #001529BB;
    color: #FFF;
    padding: 5px 10px;
    border-radius: 5px;
} */
.userDetailTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userDetailDivAvatar{
    width: 145px;
}
.userDetailAvatarImg{
    width: 100%;
}
.userDetailChannelDetailSubSection{
    width: 100%;
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.userDetailSubTitle{
    font-weight: 600;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.userDetailSubTitleFirst{
    font-weight: 600;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.userDetailSubTitleFirst span{
    font-size: 16px;
}
.userDetailCustomerMainData{
    border: 1px solid #888;
    border-width: 0 0 1px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.userDetailCustomerMainDataLabel{
    font-family: "微軟正黑體";
    font-weight: 800;
    text-align: left;
}
.userDetailCustomerMainDataSpan{
    font-family: "微軟正黑體";
    font-weight: 400;
    text-align: left;
    overflow: auto;
    overflow-wrap: break-word;
    white-space: break-spaces;
}
.userDetailwpId{
    border: 1px solid #888;
    border-width: 0 0 1px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.userDetailRecentOrder{
    /* margin: 0 0 0 24px; */
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.userDetailCustomerMainDataDiv{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.userDetailRecentOrderSec{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #000;
    width: 75%;
}
.userDetailRecentOrderDiv{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    border: 1px solid #000;
}
.userDetailChannelContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.userDetailChannel{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFF;
    margin: 3px;
    padding: 5px;
    border-radius: 5px;
}
.userDetailChannelDetailContainer, .userDetailTagContainer, .userDetailTagDetailContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.userDetailTagContainer{
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.userDetailTagDetailContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
/* .userDetailDivTag{
    display: flex;
    align-items: flex-start;
    justify-content: center;
} */
.userDetailDivTag2{
    width: 100%;
    margin: auto;
}
.userDetailChannelDetail, .userDetailTag, .userDetailSettingTag{
    /* min-width: 250px;
    max-width: 260px; */
    margin: 3px;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.userDetailChannelDetail{
    padding: 0;
}
.userDetailSettingTag{
    width: 50%;
}
/* .userDetailChannelDetail .userDetailSubTitle:nth-child(1), .userDetailTag .userDetailSubTitle:nth-child(1), .userDetailSettingTag .userDetailSubTitle:nth-child(1){
    width: 100%;
    text-align: center;
} */
/* .userDetailSubTitle.userDetailSubTitleName{
    justify-content: center;
} */
.userDetailSubTitleName{
    margin: 1px auto;
    text-align: center;
}
.userDetailSubTitleName span{
    font-weight: 600;
    font-size: 15px;
}
.userDetailTagIconDiv{
    /* margin: 4px 1px; */
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.userDetailTagIconDiv span{
    margin: 4px;
}
.userDetailTagIcon{
    padding: 2px 5px;
    font-size: 14px;
}
.userDetailDivTags .userDetailSubTitle span{
    font-size: 12px;
}
.userDetailTitleTag{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.userDetailTitleTag h6{
    margin-bottom: 0;
}
.userDetailTitleTagH6{
    margin: 4px!important;
    width: 100%;
}
.userDetailTitleTagsUI{
    margin: 4px;
}
.userDetailTags{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 4px 4px;
    font-size: 14px;
}
.userDetailTags span[role="img"]{
    color: #1d39c488;
}
.userDetailTags span[role="img"]:hover{
    color: #1d39c4;
}
.userDetailTag{
    width: fit-content;
    min-width: initial;
    max-width: none;
    padding: 4px 8px;
    align-items: center;
    background-color: #33658A;
    color:#FFF;
}
.userDetailNoTags{
    align-items: center;
    background-color: #1677FE88;
    color: #FFF;
    padding: 2px 4px;
}
.userDetailSettingTag{
    border-color: #33658A;
    color:#33658A;
    width: fit-content;
    min-width: initial;
    max-width: initial;
}
.userDetailTagButtonDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.userDetailTagButton{
    margin: 5px;
    padding: 2px 5px;
}
@media screen and (max-width: 767px) {
    .userDetailTitleSec{
        /* min-width: 260px; */
        height: 50px;
        padding: 8px;
    }
    .userDetailH6{
        margin-left: 10%;
        text-align: left;
        font-size: 14px;
    }
    .userDetailTagDetailContainer{
        width: 100%;
    }
}