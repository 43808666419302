.userListAssignSec{
    border-left: initial;
    display: flex;
    flex-direction: row;
    align-items: center;

    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
    width: 100%;
    /* min-width: 260px; */
    padding: 8px;
    background-color: #001529;
    border-left: 1px solid white;
    box-shadow: 2px 5px 10px -5px #33333366;
}
.userListAssignH6{
    margin: 0;
    width: fit-content;
    color: #FFF;
    flex: 6;
    margin-left: 3%;
    font-size: 1rem;
}
.userListContentSec{
    width: 100%;
    padding: 14px 10px;
    flex: 2;
}
@media screen and (max-width: 991px) {
    .userListAssignH6{
        flex: 6;
        margin-left: 12%;
    }
    .userListContentSec{
        flex: 2;
    }
}
@media screen and (max-width: 767px) {
    .userListAssignSec{
        height: 50px;
        border-left: initial;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;
    }
    .userListAssignH6{
        flex: 6;
        margin-left: 5%;
        text-align: left;
        font-size: 14px;
    }
    .userListContentSec{
        flex: 2;
    }
}