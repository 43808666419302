.table__container tbody tr {
  cursor: pointer;
  user-select: none;
}

.approving-status--agree {
  color: #16a34a;
}
.approving-status--reject {
  color: red;
}
