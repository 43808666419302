.container {
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: row-reverse;
  gap: 1rem;
}

.image-detail {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.image-uploader {
  display: inline-flex !important;
  justify-content: center;
  align-items: baseline;
  gap: 2px;

  padding: 5px 10px;
  border: 1px solid #777;
  border-radius: 10px;

  cursor: pointer;
  transition: all 0.3s ease-in;
}

.preview-image__container {
  margin: 0 !important;
  padding: 0 !important;
  width: 400px;
}
.preview-image {
  height: 100%;
  width: 100%;
}
