.tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  margin: 4px 4px;

  font-size: 14px;

  cursor: pointer;
}
.tag span[role='img'] {
  color: #1d39c488;
}
.tag span[role='img']:hover {
  color: #1d39c4;
}
