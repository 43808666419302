.container {
  height: 500px;
  width: 500px;

  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 1.25rem;
}

.block {
  width: 100%;
  margin: 0.5rem 0;
}

.selectable-tags {
  flex-grow: 1;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tag-filter {
  margin: 0.25rem 0;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}
