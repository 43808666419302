.container {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
  padding: 4rem 0;
}


.link:link,
.link:visited {
  padding: 8px;
  border-radius: 10px;

  font-size: 14px;
  text-decoration: none;
  color: white;
  background-color: #1677ff;

  transition: all 0.5s ease-in;
}

.link:hover,
.link:active {
  background-color: #4592ff;
}
