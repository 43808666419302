.label {
  font-size: 16px !important;
  font-weight: 500;
  text-align: center;
}

.form-item__container {
  margin: 0 auto 1rem;
}

.form-item__row--1 {
  display: grid;
  grid-template-columns: 1fr max-content;

  margin-bottom: 0.5rem;
}
