.socketCustomerSendMsgDiv{
    width: 98%;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.socketCustomerSendMsgForm{
    width: 100%;
}
.socketCustomerInput{
    /* height: 50px; */
    height: 84px;
    border-radius: 2px;
}
/* .socketCustomerInput input{
    width: 50px;
} */
.socketCustomerSendButtonDiv, .socketCustomerSendButtonDivDisabled{
    display: flex;
    flex-direction: row;
}
.socketCustomerSendButtonDivDisabled div{
    opacity: .8;
    cursor: no-drop;
}
.socketCustomerSendButton:disabled, .socketCustomerSendButton:disabled:hover{
    opacity: .8;
    cursor: no-drop;
}
/* .socketCustomerSendButton{
    background-color: rgb(22, 119, 254);
    color: white;
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
    flex-direction: row;
    font-size: 12px;
    justify-content: center;
    outline: none;
    padding: 8px;
    height: initial;
    position: relative;
    text-align: center;
    transition: all .15s ease;
    -webkit-user-select: none;
    user-select: none;
}
.socketCustomerSend:hover{
    background-color: #4592FE;
    color: white!important;
} */
.socketCustomerBtn textarea{
    height: 50px!important;
    overflow: scroll!important;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    overflow: -moz-scrollbars-none; /* Old Firefox */
    scrollbar-width: none;  /* Firefox */
}
.socketCustomerBtn textarea::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.socketCustomerEmoji{
    position: fixed;
    right: 125px;
    top: initial!important;
    bottom: 24vh;
}
@media screen and (max-width: 991px) {
    .socketCustomerEmoji{
        /* top: 26%!important; */
        bottom: 29vh;
    }
}
@media screen and (max-width: 767px) {
    .socketCustomerSendButton span{
        font-size: 12px;
    }
    .socketCustomerEmoji{
        /* top: 27.5%!important; */
        right: 17.3%;
    }
}
@media screen and (max-width: 540px) {
    .socketCustomerEmoji{
        /* top: 23.5%!important; */
        bottom: 25vh;
        right: 20%;
    }
}
@media screen and (max-width: 480px) {
    .socketCustomerEmoji{
        /* bottom: 22%; */
        right: 14%;
    }
}
@media screen and (max-width: 420px) {
    .socketCustomerEmoji{
        /* bottom: 23vh; */
        right: 7.3%;
    }
}
@media screen and (max-width: 300px) {
    .socketCustomerSendButton{
        padding: 4px;
    }
}
