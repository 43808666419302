.form__container {
  max-width: 600px;
  margin: 2rem auto 0;
}

.input-texts {
  display: flex;
  justify-content: center;
  gap: 1rem;

  margin-bottom: 0.5rem;
}
.input-texts input {
  min-width: 200px;
}

.input__radio {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  margin-bottom: 0.5rem;
}

.form__submit-button {
  margin-top: 1rem;
  margin-left: 1rem;
}
