.container {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  overflow: hidden;
}

.preview-area {
  border: 1.5px solid #000000;

  background-color: #00000055;

  user-select: none;
}
.preview-area__serial-number {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;

  display: inline-block;
  height: 2rem;
  width: 2rem;
  border-radius: 9999px;

  font-size: 20px;
  background-color: #ffffff99;
}
