.message-item {
  margin-top: 1rem;
}
.message-item > p {
  margin-bottom: 0;
}

.message-item__content {
  position: relative;

  padding: 0.5rem;
  border: 1px solid rgba(5, 5, 5, 0.06);
  border-top: 0;
  border-radius: 8px;

  overflow: auto;
}

.message-item__flex-message__btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  border: none;
  border-radius: 0.5rem;

  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.04);

  transition: all 0.2s ease-in;
}
.message-item__flex-message__btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
