.rich-menu-item {
  position: relative;

  /* [TEMP] */
  /* 
  display: grid;
  grid-template-rows: 1fr min-content;
  */

  /* height: 200px; */
  width: 200px;
  padding: 0.5rem 0;
  border-radius: 1rem;
  overflow: hidden;

  background-color: #ffffff77;

  list-style: none;
}

.rich-menu-item > figure {
  cursor: pointer;
}

.rich-menu-item__info {
  grid-row: 2;
  padding: 0.5rem;

  /* [TEMP] */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.rich-menu-item__control {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rich-menu-item__name {
  width: 150px;
  margin: 0;

  font-size: 18px;
  font-weight: 400;
  text-align: start;
  white-space: nowrap;
  overflow: clip;
  text-overflow: ellipsis;
}

.rich-menu-item__create-at {
  margin: 0;

  font-size: 12px;

  /* [TEMP] */
  text-align: start;
}
