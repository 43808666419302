.overlay {
  z-index: 1002;
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100%;

  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);

  transition: all 0.5s;
}

.dialog {
  z-index: 1003;
  position: fixed;
  top: 50%;
  left: 50%;

  min-width: 300px;
  max-width: 500px;
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0 2.4rem 3.2rem rgba(0, 0, 0, 0.12);

  background-color: #fff;

  transform: translate(-50%, -50%);
  transition: all 0.5s;
}

.title {
  font-size: 1.5rem;
}
.title--error {
  color: #dc2626;
}
.title--confirm {
  color: #ea580c;
}

.buttons {
  display: flex;
  justify-content: end;
  gap: 0.5rem;
}
.button--warning {
  border-color: #ea580c !important;
  color: white !important;
  background-color: #ea580c !important;
}
.button--warning:hover {
  border-color: #f97316 !important;
  background-color: #f97316 !important;
}
.button--error {
  border-color: #dc2626 !important;
  color: white !important;
  background-color: #dc2626 !important;
}
.button--error:hover {
  border-color: #ef4444 !important;
  background-color: #ef4444 !important;
}
