.item__container {
  width: 100%;
}

.item__operations {
  display: flex;
  justify-content: space-between;

  margin-bottom: 0.3rem;
}

.item__label {
  display: block !important;

  width: 100%;

  font-size: 16px;
  font-weight: 500;
  text-align: start;
}

.item__input div div div {
  min-height: 0 !important;
}
.item__input > div {
  margin: 0 !important;
}

.item__text-area {
  margin-top: 0.3rem;
}
