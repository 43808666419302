.console__container {
  display: flex;
  flex-direction: column;

  height: calc(92vh - 46px);
  padding: 10px;
}

.console__buttons {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
