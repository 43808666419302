.container {
  width: max-content;
  margin: 0 auto;
  padding: 20px 40px;
}

@media screen and (width < 650px) {
  .container {
    padding: 20px;
  }
}

.search-bar {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 1rem;

  margin-bottom: 1rem;
}
.search-bar__block {
  display: flex;
  flex-direction: column;
}
.search-bar__block label {
  align-self: flex-start;
}

.pagination {
  margin-top: 1rem;
}
