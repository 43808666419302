.container {
  display: flex;
  flex-direction: column;

  height: calc(92vh - 46px);
  padding: 10px;
}

.container__form {
  flex-basis: 1;

  margin-top: 1rem;

  overflow-y: auto;
}

@media screen and (width < 1510px) {
  .container {
    height: calc(100vh - 46px);
  }
}
