.input-action__container {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 0.5rem;
}

.input-action__label-input div {
  height: 100%;
}
.input-action__label-input input {
  height: 100%;
}

.input-action__content {
  grid-column: 1 / -1;
}
