span.ant-tag.ant-tag-geekblue{
    padding: 2px 6px;
}
/* #TagsRead .ant-layout-content {
    overflow-y: hidden;
} */
/* 分頁區塊 */
#TagsRead .ant-list .ant-list-pagination {
    margin-block-start: 24px;
    margin-block-end: 24px;
}
/* 新增Tag頁面查詢區塊 */
#TagsAdd .ant-list-item-meta-content {
    width: 100%!important;
}
/* @media screen and (max-width: 767px) {
    #TagsRead .ant-layout-content {
        overflow-y: hidden;
    }
} */
@media screen and (max-width: 767px){
    span.ant-tag.ant-tag-geekblue{
        font-size: 14px;
    }
}