.overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100%;

  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);

  transition: all 0.5s;
}

.modal {
  z-index: 1001;
  position: fixed;
  top: 50%;
  left: 50%;

  padding: 1.2rem;
  padding-top: 2.5rem;
  border-radius: 9px;
  box-shadow: 0 2.4rem 3.2rem rgba(0, 0, 0, 0.12);

  background-color: #fff;

  transform: translate(-50%, -50%);
  transition: all 0.5s;
}

@media screen and (width < 600px) {
  .modal {
    padding: 10px;
  }
}

.close-modal-button {
  z-index: 1;

  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  padding: 0.2rem;
  border: none;
  border-radius: 9999px;

  background-color: #f3f4f688;

  transition: all 0.2s;
}
.close-modal-button:hover {
  background-color: #f3f4f6;
}
.close-modal-button svg {
  width: 20px;
}
